import CryptoJS from 'crypto-js'

const members = {
  '629376df112eac4f7a90378e_tree0614': 'a', // moneyTree Test  - 윤수 dev
  '629376df112eac4f7a90378e_cons0911': 'b', // moneyTree Test  - 윤수 dev
  '63fe1548f61c2dfe055de869_tree0614': 'b', // webCheckIn Test  - 윤수 prod 개발폰
  '62271be8b3ef552fb2dd424d_tree0614': 'a', // webCheckIn Test  - 윤수 prod
  '62271be8b3ef552fb2dd424d_pack0824': 'b', // webCheckIn Test  - 윤수 prod
  '62271be8b3ef552fb2dd424d_fimp1208': 'b', // webCheckIn Test  - 윤수 prod
  '63fe1548f61c2dfe055de869_fimp1208': 'a', // webCheckIn Test  - 윤수 prod
  '63fe1548f61c2dfe055de869_gifti1221': 'b', // 체리피커처단 Test - 윤수 개발폰 prod
  '62271be8b3ef552fb2dd424d_gifti1221': 'b', // 체리피커처단 Test - 윤수 찐폰 prod
  '61a5a1313a1787613ebc7f2f_gifti1221': 'b', // 체리피커처단 Test - 소현 prod
  '6505464c8060a4a4b8b81853_cost1220': 'b', // 서윤
  '61a5a1313a1787613ebc7f2f_cost1220': 'a', // 소현
  '62271be8b3ef552fb2dd424d_share0207': 'b', // toon Test  - 윤수 prod
  '63fe1548f61c2dfe055de869_share0207': 'b', // toon Test - 윤수 개발폰 prod
  '626aa6a6c4d18f4110ecd6cb_buzzvilOfferwall0717': 'b',
  '643611979331c3827c8769a6_buzzvilOfferwall0717': 'b',
  '66a206b38b54b6add496e38c_buzzvilOfferwall0717': 'b', // buzzvil 윤상혁 매니저님
  '660f5187d674252fe0453e66_buzzvilOfferwall0717': 'b', // buzzvil 김동균 매니저님
  '63ea25fc7acef407511ff71c_buzzvilOfferwall0717': 'b', // buzzvil 장승원 매니저님
  '634cd960677f1e3b8b9ff306_admobAltoon0624': 'b', // 종근
  '66cbd7d8acab18533caab325_dailyCheckInImpLog1106': 'b', // 혁진
  '620cce8196d049583db642e3_dailyCheckInImpLog1106': 'b', // 혁진
  '634cd960677f1e3b8b9ff306_offerwallDailyCheckIn1118': 'd', // 종근
  '64d98ee742e2c30efc87d6e5_offerwallDailyCheckIn1118': 'b', // 민국
  '63bfb30b14a835810aacffb3_offerwallDailyCheckIn1118': 'c', // 은지
  '626aa6a6c4d18f4110ecd6cb_offerwallDailyCheckIn1118': 'd', // 욱
}

const map = 'abcdefghij'

const AB_V3 = (id = '', key = 'test0000', g = [50]) => {
  if (!id) return 'a'
  if (`${id}_${key}` in members) {
    return members[`${id}_${key}`]
  }

  const hashed = CryptoJS.HmacSHA256(key, id).toString(CryptoJS.enc.Hex)
  const code = (parseInt(hashed?.slice(-8), 36) % 10000) / 100

  let r = ''
  g.forEach((each, i) => {
    if (code < each && !r) r = map[i]
  })

  if (!r) r = map[g.length]
  return r
}

export default AB_V3
