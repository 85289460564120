import { useActivity } from '@stackflow/react'
import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import AltoonAction from '../../actions/AltoonAction'
import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import { useNavigateViewer } from '../../hooks/altoon/useNavigateViewer.ts'
import { CloseThin } from '../../images/dailyCheckIn'
import AltoonUserStore from '../../stores/AltoonUserStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis.ts'
import resize from '../../utils/resize'
import AlwayzImage from '../atoms/AlwayzImage'
import AltoonTagComponent from '../toon/AltoonTagComponent'
import THImageText from '../treasureHunt/THImageText'
import TreasureHuntButton from '../treasureHunt/TreasureHuntButton'

const ToonModal = observer(
  ({
    modalName = 'ticketInfo',
    modalProp = [],
    setOpenTicketModal = '',
    toonId,
    coinCount = 0,
    setModalType = '',
    setModalProp = '',
    modalTop,
    scrollRef = 0,
  }) => {
    const { pop, push, replace } = useMyFlow()
    const activity = useActivity()

    const { navigateToViewer } = useNavigateViewer()

    const toonPrice = modalProp.toonInfo?.toonPrice || 300

    if (modalName === 'ticketPurchased') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='px-8 py-4 mb-2 text-[4vw] font-medium '>
              <div className='mb-4 text-[6vw] font-bold text-center'>안내</div>
              <div>
                <div className='mb-2 relative leading-relaxed'>
                  이미 첫구매 혜택을 받으셨어요.
                </div>
                <div className='relative leading-relaxed'>
                  첫구매 혜택은 한 번만 받을 수 있어요.
                </div>
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg'
                onClick={async () => {
                  push('AltoonTicketPage')
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'ticketExchange') {
      const goodsKOR = [
        { goods: 'ticket', goodsKOR: '열람권' },
        { goods: 'toonCoin', goodsKOR: '코인' },
      ]
      const goodsType = modalProp.goods
      const goodsObj = goodsKOR.find((item) => item.goods === goodsType)
      const goodsNameKOR = goodsObj ? goodsObj.goodsKOR : '본 상품'
      const coinCount =
        AltoonUserStore?.userData?.toonCoin > 0
          ? AltoonUserStore?.userData?.toonCoin || 0
          : 0

      const Title = () => {
        return <div className='pt-4'>{goodsNameKOR}을 충전할까요?</div>
      }

      const StatusComponent = () => {
        return (
          <>
            <img
              src={`/images/toon/${goodsType}.png`}
              alt='goods'
              className='w-[16vw] h-[16vw] mx-1 p-2'
            />
            {goodsType === 'ticket' && (
              <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='goods'
                  className='w-[4vw] h-[4vw] mx-1'
                />
                보유 코인 : {coinCount}C
              </div>
            )}
            {goodsType === 'toonCoin' && (
              <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='goods'
                  className='w-[4vw] h-[4vw] mx-1'
                />
                충전하실 금액을 선택해주세요
              </div>
            )}
          </>
        )
      }

      const ItemList = () => {
        const ItemComponent = ({
          itemId,
          rewardType,
          rewardAmount,
          cost,
          unit,
        }) => {
          return (
            <div className='py-2 flexRow items-center justify-between w-full border-b'>
              <div className='flexRow items-center justify-start text-[4.2vw] font-semibold'>
                <img
                  src={`/images/toon/${rewardType}.png`}
                  alt='ticket'
                  className='w-[7vw] h-[7vw] mr-2'
                />
                {goodsNameKOR}
                <div className='ml-1'>
                  {goodsType === 'toonCoin'
                    ? `${commaNumber(rewardAmount)}C`
                    : `${commaNumber(rewardAmount)}개`}
                </div>
              </div>
              <TreasureHuntButton
                className={`font-bold text-[3vw] shadow-none px-4 ${
                  goodsType === 'ticket'
                    ? 'bg-[#EB4F47] hover:bg-red-700'
                    : 'bg-[#FF8317] hover:bg-orange-800'
                }`}
                style={{}}
                clickable={goodsType === 'toonCoin' ? true : coinCount > cost}
                text={`${commaNumber(cost)} ${unit}`}
                onClick={() => {
                  setOpenTicketModal(false)
                  if (goodsType === 'toonCoin') {
                    push('AltoonItemPage', {
                      itemId,
                      component: 'modal_buyCoin',
                    })
                  } else {
                    setModalProp({
                      goodsNameKOR,
                      rewardType,
                      rewardAmount,
                      cost,
                      unit,
                      coinCount,
                    })
                    setModalType('ticketExchangeConfirm')
                    setOpenTicketModal(true)
                  }
                }}
              />
            </div>
          )
        }
        if (goodsType === 'ticket') {
          return (
            <>
              <div className='p-4 w-full flexCol justify-center items-center'>
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={1}
                  cost={300}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={5}
                  cost={1500}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={10}
                  cost={2700}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={20}
                  cost={5400}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={50}
                  cost={13500}
                  unit='C'
                />
              </div>
            </>
          )
        }
        if (goodsType === 'toonCoin') {
          return (
            <>
              <div className='p-4 w-full flexCol justify-center items-center'>
                <ItemComponent
                  itemId='65a48f90ac6f4c5c255acd36'
                  rewardType='toonCoin'
                  rewardAmount={1250}
                  cost={1500}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bef1ac6f4c5c255acd38'
                  rewardType='toonCoin'
                  rewardAmount={2500}
                  cost={3000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bf55ac6f4c5c255acd39'
                  rewardType='toonCoin'
                  rewardAmount={5000}
                  cost={6000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bf7bac6f4c5c255acd3a'
                  rewardType='toonCoin'
                  rewardAmount={10000}
                  cost={12000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4c038ac6f4c5c255acd3b'
                  rewardType='toonCoin'
                  rewardAmount={25000}
                  cost={30000}
                  unit='원'
                />
              </div>
            </>
          )
        }
      }
      const ButtonComponent = () => {
        return (
          <div className='w-full flex flex-row text-[4.2vw]'>
            <button
              type='button'
              className={`py-4 w-full 
              ${
                goodsType === 'ticket'
                  ? 'bg-[#EB4F47] hover:bg-red-700'
                  : 'bg-[#FF8317] hover:bg-orange-800'
              }
                transition ease-in duration-200 text-white rounded-b-lg`}
              onClick={async () => {
                setOpenTicketModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      }
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='absolute top-[16vw] left-[10vw] z-30 w-4/5 h-[146vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
          >
            <div />
            <div className='px-4 text-center flex flex-col items-center justify-center text-lg'>
              <Title />
              <StatusComponent />
            </div>
            <ItemList />
            <ButtonComponent />
          </div>
        </>
      )
    }

    if (modalName === 'ticketExchangeConfirm') {
      const Title = () => {
        return (
          <div className='py-4'>{modalProp.goodsNameKOR}을 충전할까요?</div>
        )
      }

      const InfoSection = () => {
        return (
          <div className='font-normal text-[4vw] pb-2'>
            <div>
              코인 {commaNumber(modalProp.cost)}
              {modalProp.unit}으로
            </div>
            <div>
              <span className='font-bold'>
                {' '}
                {modalProp.goodsNameKOR} {modalProp.rewardAmount}개
              </span>
              를 충전할게요
            </div>
          </div>
        )
      }

      const StatusComponent = () => {
        return (
          <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
            <img
              src='/images/toon/toonCoin.png'
              alt='goods'
              className='w-[4vw] h-[4vw] mx-1'
            />
            보유 코인 : {commaNumber(modalProp.coinCount)}C
          </div>
        )
      }

      const ButtonComponent = () => {
        return (
          <button
            type='button'
            className='my-4 py-2 w-4/5 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-[4.2vw] text-white rounded'
            onClick={async () => {
              const result = await backendApis.exchangeToonCoin(
                modalProp.rewardType,
                modalProp.rewardAmount,
                modalProp.cost,
              )
              if (result?.status === 200) {
                if (modalProp.rewardType === 'ticket') {
                  if (AltoonUserStore?.userData?.ticket) {
                    AltoonUserStore.increase(
                      'userData.ticket',
                      modalProp.rewardAmount,
                    )
                  } else {
                    AltoonUserStore.set(
                      'userData.ticket',
                      modalProp.rewardAmount,
                    )
                  }
                }

                AltoonUserStore.increase(
                  'userData.toonCoin',
                  modalProp.cost * -1,
                )
              } else {
                ToastStore.networkError()
              }
              setOpenTicketModal(false)
            }}
          >
            교환하기
          </button>
        )
      }
      return (
        <>
          <button
            type='button'
            aria-label='close'
            className='absolute top-[26vw] right-[10vw] w-[10vw] h-[10vw] flex items-center justify-center z-40'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          >
            <CloseThin className='' />
          </button>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='absolute top-[40vw] left-[10vw] z-30 w-4/5 h-[100vw] bg-white font-bold flex flex-col items-center justify-around rounded-xl'
          >
            <div />
            <div className='px-4 text-center flex flex-col items-center justify-center text-lg'>
              <Title />
              <img
                src='/images/toon/ic_ticketandpoint_big.png'
                alt='ticket'
                className='w-[16vw] h-[16vw] mx-1 mb-2'
              />
              <InfoSection />
              <StatusComponent />
            </div>
            <ButtonComponent />
          </div>
        </>
      )
    }

    if (modalName === 'bulkPurchase') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='fixed top-[25vh] left-[10vw] z-30 w-4/5 h-[40vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
          >
            <button
              type='button'
              className='px-4 py-2 mr-2 mt-2 mb-1 flex flex-row items-center justify-center self-end bg-[#FAEBEB] text-[2vw] rounded-full'
              onClick={() => {
                push('AltoonTicketPage')
              }}
            >
              내 코인
              <img
                src='/images/toon/toonCoin.png'
                alt='toonCoin'
                className='w-4 h-4 mx-1'
              />
              : {`${commaNumber(AltoonUserStore?.userData?.toonCoin)}`}C
            </button>
            <div className='px-4 py-4 text-center flex flex-col items-center justify-center text-[4.5vw]'>
              <div>전체 회차를 한번에 대여하시겠어요?</div>
              <div className='px-[16vw] py-[4vw] m-2 border rounded flexCol items-start text-[3.8vw]'>
                <div className='self-center pb-2'>
                  총 유료회차 {commaNumber(modalProp?.paidChapterCount)}개
                </div>
                <div className='text-gray-600 text-[3vw]'>
                  정가:{' '}
                  <span className='line-through'>
                    {commaNumber(modalProp?.paidChapterCount * toonPrice)}C
                  </span>
                </div>
                <div className=' text-[3.4vw]'>
                  할인가: {commaNumber(modalProp?.totalPrice)}C
                </div>
              </div>
              <div className='text-start pt-2 text-xs text-[2.6vw] text-gray-500'>
                <div className=''>
                  * 모든 회차를 3일 동안 무제한으로 볼 수 있어요
                </div>
                <div className=''>* 결제 후 환불은 불가능해요</div>
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (
                    AltoonUserStore?.userData?.toonCoin < modalProp?.totalPrice
                  ) {
                    setOpenTicketModal(false)
                    modalProp?.handleLackCoin()
                  } else {
                    setOpenTicketModal(false)
                    modalProp?.handleSuccess()
                  }
                }}
              >
                전체 구매하기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'useCoin') {
      const [isLoading, setIsLoading] = useState(false)
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[17vw]'
                : 'right-[17vw]'
            } top-[30vh] w-[66vw] z-30 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div />
            <div className='p-4 text-center flex flex-col items-center justify-center text-lg'>
              <div>코인 {toonPrice}C를 사용해서</div>
              <div>다음 회차를 보시겠어요?</div>
              <button
                type='button'
                className='px-4 py-1 mr-2 mt-4 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[4vw] rounded-lg'
                onClick={() => {
                  push('AltoonTicketPage')
                }}
              >
                보유
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />
                : {`${AltoonUserStore?.userData?.toonCoin}`}C
              </button>
              <div className='pt-4 text-xs text-[2.6vw] text-gray-500'>
                {modalProp?.chapter?.onlyPaidCoin
                  ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                  : '* 코인은 미션을 통해 무료로 받을 수 있어요'}
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)
                  if (modalProp?.chapter?.onlyPaidCoin) {
                    // 유료결제코인으로만 구매 가능한 회차인 경우
                    if (AltoonUserStore?.userData?.paidCoin < toonPrice) {
                      ToastStore.toastOn({
                        type: 'error',
                        message: '무료로 획득한 코인으로는 볼 수 없는 회차에요',
                        duration: 2000,
                      })
                      setOpenTicketModal(false)
                      return
                    }
                  }

                  const result = await backendApis.useToonCoin(
                    toonId,
                    modalProp?.chapter?._id,
                    toonPrice,
                  )
                  AltoonUserStore?.increase('userData.toonCoin', toonPrice * -1)
                  if (AltoonUserStore?.userData?.paidCoin >= toonPrice) {
                    AltoonUserStore?.increase(
                      'userData.paidCoin',
                      toonPrice * -1,
                    )
                  }
                  setOpenTicketModal(false)
                  if (result?.status === 200) {
                    const navigationParams = {
                      state: {
                        chapterInfo: modalProp.chapter,
                        toonInfo: modalProp.toonInfo,
                        episode: modalProp.episode,
                        rentPeriod: modalProp.rentPeriod,
                        newSwiperIndex: 0,
                      },
                    }
                    // 현재 페이지가 AltoonReaderPage인 경우 replace, 아닌 경우 push 사용
                    const navigateMethod =
                      activity.name === 'AltoonReaderPage' ? replace : push
                    navigateMethod('AltoonReaderPage', navigationParams)

                    if (scrollRef) {
                      scrollRef.scrollTop = 0
                    }
                  } else {
                    ToastStore.networkError()
                    replace('AltoonMainPage', {
                      component: 'modal_useCoin_error',
                    })
                  }
                  setIsLoading(false)
                }}
              >
                다음 화 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'useWaitForFree') {
      const [isLoading, setIsLoading] = useState(false)
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } top-[30vh] w-[80vw] z-30 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div />
            <div className='py-[4vw] mt-[4vw] text-center flex flex-col items-center justify-center text-lg'>
              <div>기다무를 사용하여</div>
              <div>다음 회차를 보시겠어요?</div>
              <div className='font-normal text-[3.4vw] text-[#9C9DA4]'>
                기다무는{' '}
                {AltoonUserStore?.currentViewLog?.waitForFreeInfo?.waitingTime}
                시간 후 다시 생겨요
              </div>
              <div className='w-[60vw] px-[4vw] py-[2vw] my-[4vw] flexRow items-center justify-between  bg-[#F6F6FA] text-[4vw] rounded-lg'>
                <div className='font-normal'>잔여 기다리면 무료</div>
                <div>
                  {AltoonUserStore?.currentViewLog?.waitForFreeAvailable
                    ? 1
                    : 0}
                  회
                </div>
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)
                  if (modalProp?.isWaitForFreeLimitedChapter === true) {
                    // 기다무로 볼 수 없는 회차인 경우
                    ToastStore.toastOn({
                      type: 'error',
                      message: `최신 ${
                        modalProp?.viewLog?.waitForFreeInfo
                          ?.recentChapterLimit || 10
                      }개 회차는 기다무로 볼 수 없어요`,
                      duration: 2000,
                    })

                    setOpenTicketModal(false)
                    return
                  }

                  const result = await backendApis.useWaitForFree(toonId)

                  AltoonUserStore?.set(
                    'currentViewLog.waitForFreeAvailable',
                    false,
                  )

                  setOpenTicketModal(false)
                  if (result?.status === 200) {
                    const navigationParams = {
                      state: {
                        chapterInfo: modalProp.chapter,
                        toonInfo: modalProp.toonInfo,
                        episode: modalProp.episode,
                        rentPeriod: modalProp.rentPeriod,
                        newSwiperIndex: 0,
                      },
                    }
                    // 현재 페이지가 AltoonReaderPage인 경우 replace, 아닌 경우 push 사용
                    const navigateMethod =
                      activity.name === 'AltoonReaderPage' ? replace : push
                    navigateMethod('AltoonReaderPage', navigationParams)

                    if (scrollRef) {
                      scrollRef.scrollTop = 0
                    }
                  } else {
                    ToastStore.networkError()
                    replace('AltoonMainPage', {
                      component: 'modal_useWaitForFree_error',
                    })
                  }
                  setIsLoading(false)
                }}
              >
                다음 화 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'lackCoin') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[16vw]'
                : 'right-[16vw]'
            } z-30 w-[66vw] h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
            style={{ top: `${modalTop}px` }}
          >
            <div className='self-end flex flex-col items-center'>
              <div className='px-4 py-2 mr-2 mt-2 mb-1 flex flex-row items-center justify-center bg-[#FAEBEB] text-[3vw] rounded-full'>
                내 코인
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />{' '}
                : {`${AltoonUserStore?.userData?.toonCoin}`}C
              </div>
            </div>
            <div className='px-4 mb-2 text-[4vw] text-center font-medium'>
              <div className='text-[5vw] font-bold'>코인이 부족해요</div>
              <div className='pt-2 text-xs text-[2.6vw] text-gray-500'>
                * 코인은 미션을 통해 무료로 받을 수 있어요
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  push('AltoonTicketPage')
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'rentExpired') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className='fixed top-[30vh] left-[10vw] z-30 w-4/5 h-[40vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
            style={{ top: `${modalTop}px` }}
          >
            <div className='px-4 py-2 mr-2 mt-2 self-end flex flex-row items-center justify-center bg-[#FAEBEB] text-[2vw] rounded-full'>
              보유 코인:
              <img
                src='/images/toon/toonCoin.png'
                alt='toonCoin'
                className='w-4 h-4 mx-1'
              />
              {`${AltoonUserStore?.userData?.toonCoin}`}C
            </div>
            <div className='px-4 text-center'>
              <div className='text-lg'>무료 열람기간이 만료되었어요.</div>
              <div className='text-lg'>본 회차를 다시 보시겠어요?</div>
              <div className='pt-1 text-xs text-gray-500 font-bold'>
                {modalProp?.chapter?.onlyPaidCoin
                  ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                  : `* 코인이 ${toonPrice || 300}C 사용돼요`}
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                    if (modalProp?.chapter?.onlyPaidCoin) {
                      // 유료결제코인으로만 구매 가능한 회차인 경우
                      if (AltoonUserStore?.userData?.paidCoin < toonPrice) {
                        ToastStore.toastOn({
                          type: 'error',
                          message:
                            '무료로 획득한 코인으로는 볼 수 없는 회차에요',
                          duration: 2000,
                        })
                        setOpenTicketModal(false)
                        return
                      }
                    }
                    const result = await backendApis.useToonCoin(
                      toonId,
                      modalProp?.chapter?._id,
                      toonPrice,
                    )
                    AltoonUserStore?.increase(
                      'userData.toonCoin',
                      toonPrice * -1,
                    )
                    if (AltoonUserStore?.userData?.paidCoin >= toonPrice) {
                      AltoonUserStore?.increase(
                        'userData.paidCoin',
                        toonPrice * -1,
                      )
                    }
                    setOpenTicketModal(false)
                    if (result?.status === 200) {
                      const navigationParams = {
                        state: {
                          chapterInfo: modalProp.chapter,
                          toonInfo: modalProp.toonInfo,
                          episode: modalProp.episode,
                          rentPeriod: modalProp.rentPeriod,
                          newSwiperIndex: 0,
                        },
                      }
                      // 현재 페이지가 AltoonReaderPage인 경우 replace, 아닌 경우 push 사용
                      const navigateMethod =
                        activity.name === 'AltoonReaderPage' ? replace : push
                      navigateMethod('AltoonReaderPage', navigationParams)

                      if (scrollRef) {
                        scrollRef.scrollTop = 0
                      }
                    } else {
                      ToastStore.networkError()
                      replace('AltoonMainPage', {
                        component: 'modal_rentExpired_error',
                      })
                    }
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message: '코인이 부족해요',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  }
                }}
              >
                회차 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'ratingChapter') {
      const ratingArray = [1, 2, 3, 4, 5]
      const [ratingScore, setRatingScore] = useState(
        modalProp?.ratingScore || 5,
      )
      const GetRatingComponent = ({ index }) => {
        if (ratingScore >= index) {
          return (
            <button
              type='button'
              aria-label='rating'
              onClick={() => {
                setRatingScore(index)
              }}
              style={{ padding: 0 }}
            >
              <img
                alt=''
                style={{
                  marginRight: 2,
                  width: '9vw',
                  height: '9vw',
                }}
                src='/images/toon/star.png'
              />
            </button>
          )
        }
        return (
          <button
            type='button'
            aria-label='rating'
            onClick={() => {
              setRatingScore(index)
            }}
            style={{ padding: 0 }}
          >
            <img
              alt=''
              style={{
                marginRight: 2,
                width: '9vw',
                height: '9vw',
              }}
              src='/images/StarNone.png'
            />
          </button>
        )
      }

      return (
        <>
          <button
            type='button'
            aria-label='close'
            className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          />
          <div
            style={{ top: modalProp?.modalTop }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[18vw]'
                : 'right-[18vw]'
            } top-[30vh] z-30 h-[30vh] w-[60vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-2' />
            <div className='flex flex-col items-center text-[8vw]'>
              <div>{`${ratingScore}`}</div>
              <div className='flex flex-row py-2'>
                {ratingArray.map((value, index) => {
                  return <GetRatingComponent key={value} index={value} />
                })}
              </div>
              <div className='pt-1 text-[2.8vw] text-gray-500 font-bold'>
                별을 눌러서 별점을 매겨주세요
              </div>
            </div>
            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                disabled={ratingScore === 0}
                onClick={async () => {
                  const result = await backendApis.logToonChapterRating(
                    toonId,
                    modalProp?.chapterId,
                    ratingScore,
                    modalProp?.type,
                  )
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🤩',
                      message: '별점이 등록되었어요!',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  }
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'noNextChapter') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='fixed top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='h-10' />
            <div className='px-4 mb-2 text-[4vw] text-center font-medium'>
              <div className='text-[5vw] font-bold'>아직 다음 화가 없어요</div>
              <div className='mb-2 text-[5vw] font-bold'>
                다음 화가 연재되면 알려드릴까요?
              </div>
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  pop()
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const alarmSet = await backendApis.setToonAlarm(
                    'noNextChapter',
                    modalProp?.toonInfo?._id,
                  )
                  if (alarmSet.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🔔',
                      message: '다음 회차가 연재되면 알려드릴게요!',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  pop()
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'deleteComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 w-4/5 h-[20vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 삭제하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'delete',
                    modalProp?.commentId,
                  )
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '✅',
                      message: '댓글이 삭제되었어요',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'blockComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20 content-center' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 h-[20vh] w-[80vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 차단하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'block',
                    modalProp?.commentId,
                  )
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '⛔',
                      message: '댓글이 차단되었어요',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'reportComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`absolute top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 w-[80vw] h-[20vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 신고하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'report',
                    modalProp?.commentId,
                  )
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🚨',
                      message:
                        '댓글이 신고되었어요. 부적절한 내용이 있는지 빠르게 확인하고 조치할게요.',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'toonDescription') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[10vh] left-[10vw] z-30 w-4/5 bg-white flex flex-col items-center justify-between rounded-xl'>
            <div className='px-8 py-4 mb-2 text-[4vw] font-medium overflow-y-hidden'>
              <div className='mb-4 text-[6vw] font-bold text-center'>
                줄거리
              </div>
              <div className='font-normal max-h-[60vh]  overflow-y-scroll'>
                <THImageText
                  style={{ fontWeight: 'normal' }}
                  fontSize='3.6'
                  text={modalProp?.toonInfo?.description?.replace(/\\n/g, '\n')}
                />
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg font-bold'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'shareKakao') {
      const isFinishedToday = modalProp?.rewardedCount > 2
      const shareKakao = () => {
        AltoonAction.kakaoLinkShare({
          shareType: 'Altoon_toonShare',
          title: `[올툰] ${
            UserStore?.userInfo?.userName
              ? `${UserStore?.userInfo?.userName}님이`
              : '친구가'
          } 추천해요!`,
          description: '무료로 즐기는 꿀잼 웹툰, 올툰',
          imageUrl:
            'https://assets.ilevit.com/8a6787fd-acc0-4b28-aa59-993fe40a9240.png',
          payload: {},
          templateId: 104208,
        })
      }
      const ShareFriendComponent = ({ empty }) => {
        if (empty) {
          return (
            <button
              type='button'
              className='mx-2'
              onClick={() => {
                if (isFinishedToday) {
                  setOpenTicketModal(false)
                  return
                }
                shareKakao()
                setOpenTicketModal(false)
              }}
            >
              <img
                src='/images/toon/blackRoundPlus.png'
                alt='goods'
                className='w-[12vw] h-[12vw] mx-1'
              />
            </button>
          )
        }
        return (
          <div className='mx-2'>
            <img
              src='/images/toon/greenRoundCheck.png'
              alt='goods'
              className='w-[12vw] h-[12vw] mx-1'
            />
          </div>
        )
      }
      const renderShareFriendComponents = () => {
        const rewardedCount = modalProp?.rewardedCount || 0
        const components = []

        // rewardedCount가 3 이상이면, ShareFriendComponent를 3개 렌더
        if (rewardedCount >= 3) {
          for (let i = 0; i < 3; i++) {
            components.push(<ShareFriendComponent key={i} />)
          }
        } else {
          // rewardedCount가 1이나 2면, 해당 수만큼 ShareFriendComponent 렌더하고 나머지는 empty로 렌더
          for (let i = 0; i < rewardedCount; i++) {
            components.push(<ShareFriendComponent key={i} />)
          }
          for (let i = rewardedCount; i < 3; i++) {
            components.push(<ShareFriendComponent key={i} empty />)
          }
        }

        return components
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <button
            type='button'
            aria-label='close'
            className='absolute top-[46vw] right-[10vw] w-[10vw] h-[10vw] flex items-center justify-center z-40'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          >
            <CloseThin className='' />
          </button>
          <div className='absolute top-[60vw] left-[10vw] z-30 w-4/5 h-[70vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='h-[3vh]' />
            <div className='pt-[10vw] mb-2 text-[5vw] font-bold flexCol '>
              링크 공유만 해도
              <span className='flexRow'>
                <img
                  src='/images/toon/ticket.png'
                  alt='goods'
                  className='w-6 h-6 mx-1'
                />
                <span className='text-[#FF5E5E] mr-1'>1개</span> 받아요
              </span>
            </div>
            <div className='flexRow py-[4vw]'>
              {renderShareFriendComponents()}
            </div>
            <div className='text-[#A5A5A5] py-[5vw]'>
              {isFinishedToday
                ? '오늘은 이미 3번 받으셨어요!'
                : '매일 3번까지 받을 수 있어요!'}
            </div>
            <button
              type='button'
              className='w-full py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-b-lg'
              onClick={async () => {
                if (isFinishedToday) {
                  setOpenTicketModal(false)
                  return
                }
                shareKakao()
                setOpenTicketModal(false)
              }}
            >
              {isFinishedToday ? '확인' : '카카오 공유하기'}
            </button>
          </div>
        </>
      )
    }

    if (modalName === 'recommendRelatedToon') {
      const otherToonsByAuthor = modalProp?.toonInfo?.sortedSameAuthorToons?.[0]
      const relatedToons = modalProp?.toonInfo?.sortedRelatedToons?.[0]

      const [isLoading, setIsLoading] = useState(false)

      const ContentComponent = () => {
        if (otherToonsByAuthor?.length > 0) {
          return (
            <div className='py-[4vw] mt-[4vw] text-center flex flex-col items-center justify-center text-lg'>
              <div>같은 작가님의</div>
              <div>다른 작품은 어떠세요?</div>

              <AlwayzImage
                lazy
                src={resize(otherToonsByAuthor?.thumbnail, 254, 340, 'fill')}
                alt={otherToonsByAuthor?.title}
                className='w-[30vw] h-[40vw] my-[2vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
              />

              <div className='text-[3.6vw]'>{otherToonsByAuthor?.title}</div>
              <div className='py-[2vw] flex flex-row text-[2.4vw] overflow-scroll flex-wrap'>
                {otherToonsByAuthor?.tagList?.mainGenre
                  ?.slice(0, 1)
                  ?.map((tag) => {
                    return <AltoonTagComponent key={Math.random()} tag={tag} />
                  })}
                {otherToonsByAuthor?.tagList?.subGenre
                  ?.slice(0, 2)
                  ?.map((tag) => {
                    return <AltoonTagComponent key={Math.random()} tag={tag} />
                  })}
              </div>
            </div>
          )
        }
        return (
          <div className='py-[4vw] mt-[4vw] text-center flex flex-col items-center justify-center text-lg'>
            <div>비슷한 작품이 있어요!</div>
            <div>보러 가볼까요?</div>

            <AlwayzImage
              lazy
              src={resize(relatedToons?.thumbnail, 254, 340, 'fill')}
              alt={relatedToons?.title}
              className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
            />

            <div className='text-[3.6vw]'>{relatedToons?.title}</div>
            <div className='py-[2vw] flex flex-row text-[2.4vw] overflow-scroll flex-wrap'>
              {relatedToons?.tagList?.mainGenre?.slice(0, 1)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
              {relatedToons?.tagList?.subGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          </div>
        )
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } top-[20vh] w-[80vw] z-30 bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <ContentComponent />

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  pop()
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)
                  if (otherToonsByAuthor) {
                    replace('AltoonDetailPage', {
                      toonId: otherToonsByAuthor._id,
                      component: 'modal_recommendRelated_otherToonsByAuthor',
                    })
                  } else {
                    replace('AltoonDetailPage', {
                      toonId: relatedToons._id,
                      component: 'modal_recommendRelated_relatedToons',
                    })
                  }

                  setOpenTicketModal(false)
                  setIsLoading(false)
                }}
              >
                보러가기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'alfarmOnboarding') {
      return (
        <>
          <button
            type='button'
            aria-label='close'
            className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20 '
            onClick={() => {
              setOpenTicketModal(false)
            }}
          />
          <div
            style={{ top: modalProp?.modalTop }}
            className='fixed left-[10vw] top-[20vh] z-30 w-[80vw] bg-white flex flex-col items-center justify-between rounded-xl'
          >
            <div className='h-[4vw]' />
            <div className='flex flex-col items-center text-center text-[#30333D] text-[5vw]'>
              <div className='font-bold '>
                무료로 만화 보고 <br />
                <span className='text-[#22A3FE]'>물 50g</span> 받아가세요!
              </div>
              <div className='flex flex-row py-2 '>
                <img
                  alt=''
                  style={{
                    marginRight: 2,
                    width: '70vw',
                  }}
                  src='/images/toon/altoonFarmBridgeModal.png'
                />
              </div>
              <div className='py-[2vw] text-[3.8vw]'>
                보고싶은 작품의 한 회차를 <br /> 끝까지 보면{' '}
                <span className='text-[#22A3FE] font-bold'>물 최대 50g</span>을
                드려요
              </div>
            </div>
            <div className='w-full py-[4vw] flex justify-center'>
              <TreasureHuntButton
                className='w-[70vw] font-bold  py-[4vw] bg-[#EB4F47] hover:bg-red-700'
                clickable
                text='만화 보러 가기'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              />
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'novel_lackCoin') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className='fixed top-[30vh] right-[16vw] z-30 w-[66vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
            style={{ top: `${modalTop}px` }}
          >
            <div className='self-end flex flex-col items-center'>
              <div className='px-4 py-2 mr-2 mt-2 mb-1 flex flex-row items-center justify-center bg-[#FAEBEB] text-[3vw] rounded-full'>
                내 코인
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />{' '}
                : {`${AltoonUserStore?.userData?.toonCoin}`}C
              </div>
            </div>
            <div className='px-4 mb-2 text-[4vw] text-center font-medium'>
              <div className='text-[5vw] font-bold'>코인이 부족해요</div>
              <div className='pt-2 text-xs text-[2.6vw] text-gray-500'>
                * 코인은 미션을 통해 무료로 받을 수 있어요
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  push('AltoonTicketPage')
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'novel_useCoin') {
      const [isLoading, setIsLoading] = useState(false)

      const novelInfo = modalProp?.novelData
      const chapterInfo = modalProp?.chapterInfo
      const chapterPrice = chapterInfo?.chapterPrice

      const handleCoinUsage = async () => {
        if (AltoonUserStore?.userData?.toonCoin >= chapterPrice) {
          if (chapterInfo?.onlyPaidCoin) {
            // 유료결제코인으로만 구매 가능한 회차인 경우
            if (AltoonUserStore?.userData?.paidCoin < chapterPrice) {
              ToastStore.toastOn({
                type: 'error',
                message: '무료로 획득한 코인으로는 볼 수 없는 회차에요',
                duration: 2000,
              })
              setOpenTicketModal(false)
              return
            }
          }

          const result = await backendApis.useToonCoin(
            novelInfo?._id,
            chapterInfo._id,
            chapterPrice,
          )
          AltoonUserStore?.increase('userData.toonCoin', chapterPrice * -1)
          if (AltoonUserStore?.userData?.paidCoin >= chapterPrice) {
            AltoonUserStore?.increase('userData.paidCoin', chapterPrice * -1)
          }

          setOpenTicketModal(false)

          if (result?.status === 200) {
            navigateToViewer(
              {
                novelId: novelInfo._id,
                chapterId: chapterInfo._id,
              },
              {
                replace: true,
              },
            )
            if (scrollRef) {
              scrollRef.scrollTop = 0
            }
          } else {
            ToastStore.networkError()
            replace('AltoonMainPage', {
              component: 'modal_novelUseCoin_error',
            })
          }
        } else {
          ToastStore.toastOn({
            type: 'error',
            message: '코인이 부족해요',
            duration: 2000,
          })
          setOpenTicketModal(false)
        }
      }

      const MainTextComponent = () => {
        if (modalProp.modalType === 'rentExpired') {
          return (
            <>
              <div className='px-4 py-2 mr-2 mt-2 self-end flex flex-row items-center justify-center bg-[#FAEBEB] text-[2vw] rounded-full'>
                보유 코인:
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />
                {`${AltoonUserStore?.userData?.toonCoin}`}C
              </div>
              <div className='px-4 text-center'>
                <div className='text-lg'>무료 열람기간이 만료되었어요.</div>
                <div className='text-lg'>본 회차를 다시 보시겠어요?</div>
                <div className='pt-1 text-xs text-gray-500 font-bold'>
                  {chapterInfo?.onlyPaidCoin
                    ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                    : `* 코인이 ${chapterPrice}C 사용돼요`}
                </div>
              </div>
            </>
          )
        }

        return (
          <div className='p-4 text-center flex flex-col items-center justify-center text-lg'>
            <div>코인 {chapterPrice}C를 사용해서</div>
            <div>다음 회차를 보시겠어요?</div>
            <button
              type='button'
              className='px-4 py-1 mr-2 mt-4 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[4vw] rounded-lg'
              onClick={() => {
                push('AltoonTicketPage')
              }}
            >
              보유
              <img
                src='/images/toon/toonCoin.png'
                alt='toonCoin'
                className='w-4 h-4 mx-1'
              />
              : {`${AltoonUserStore?.userData?.toonCoin}`}C
            </button>
            <div className='pt-4 text-xs text-[2.6vw] text-gray-500'>
              {chapterInfo?.onlyPaidCoin
                ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                : '* 코인은 미션을 통해 무료로 받을 수 있어요'}
            </div>
          </div>
        )
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className='fixed top-[30vh] left-[10vw] z-30 w-4/5 bg-white font-bold flex flex-col items-center justify-between rounded-xl'
            style={{ top: `${modalTop}px` }}
          >
            <MainTextComponent />

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)

                  await handleCoinUsage()
                  setIsLoading(false)
                }}
              >
                {chapterInfo?.episode}화 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'novel_useWaitForFree') {
      const [isLoading, setIsLoading] = useState(false)

      const novelInfo = modalProp?.novelData
      const promotionInfo = novelInfo?.promotionInfo
      const chapterInfo = modalProp?.chapterInfo
      const viewLogData = modalProp?.viewLogData
      const waitForFreeAppliableChapter = modalProp?.waitForFreeAppliableChapter

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='fixed right-[10vw] top-[30vh] w-[80vw] z-30 bg-white font-bold flex flex-col items-center justify-between rounded-xl'
          >
            <div />
            <div className='py-[4vw] mt-[4vw] text-center flex flex-col items-center justify-center text-lg'>
              <div>기다무를 사용하여</div>
              <div>다음 회차를 보시겠어요?</div>
              <div className='font-normal text-[3.4vw] text-[#9C9DA4]'>
                기다무는 {promotionInfo?.waitForFree.waitingTime || 3}시간 후
                다시 생겨요
              </div>
              <div className='w-[60vw] px-[4vw] py-[2vw] my-[4vw] flexRow items-center justify-between  bg-[#F6F6FA] text-[4vw] rounded-lg'>
                <div className='font-normal'>잔여 기다리면 무료</div>
                <div>{viewLogData?.waitForFreeAvailable ? 1 : 0}회</div>
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)
                  if (!waitForFreeAppliableChapter) {
                    // 기다무로 볼 수 없는 회차인 경우
                    ToastStore.toastOn({
                      type: 'error',
                      message: `최신 ${
                        promotionInfo?.waitForFree.recentChapterLimit || 10
                      }개 회차는 기다무로 볼 수 없어요`,
                      duration: 2000,
                    })

                    setOpenTicketModal(false)
                    return
                  }

                  const result = await backendApis.useWaitForFree(
                    novelInfo?._id,
                    'novel',
                  )

                  setOpenTicketModal(false)

                  if (result?.status === 200) {
                    navigateToViewer(
                      {
                        novelId: novelInfo._id,
                        chapterId: chapterInfo._id,
                      },
                      {
                        replace: true,
                      },
                    )
                    if (scrollRef) {
                      scrollRef.scrollTop = 0
                    }
                  } else {
                    ToastStore.networkError()
                    replace('AltoonMainPage', {
                      component: 'modal_novelUseWaitForFree_error',
                    })
                  }
                  setIsLoading(false)
                }}
              >
                {chapterInfo?.episode}화 보기
              </button>
            </div>
          </div>
        </>
      )
    }
  },
)

export default ToonModal
