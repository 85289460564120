import { AppScreen } from '@stackflow/plugin-basic-ui'
import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonEventPage = observer(({ params }) => {
  const eventId = params.eventId
  const { pop } = useMyFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [eventInfo, setEventInfo] = useState()
  const [toonsInfo, setToonsInfo] = useState()
  const [novelsInfo, setNovelsInfo] = useState()
  const [participated, setParticipated] = useState(false)
  const [leaderboardInfo, setLeaderboardInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const eventInfo = await backendApis.loadToonEventInfo(eventId)
      if (eventInfo?.status === 200) {
        setEventInfo(eventInfo?.data)
        if (eventInfo?.data?.toonIdList?.length > 0) {
          const toonInfo = await backendApis.loadToonInfoByIds(
            eventInfo?.data?.toonIdList,
          )
          setToonsInfo(toonInfo?.data)
        }
        if (eventInfo?.data?.novelIdList?.length > 0) {
          const novelInfo = await backendApis.loadNovelInfoByIds(
            eventInfo?.data?.novelIdList,
          )
          setNovelsInfo(novelInfo?.data)
        }

        if (eventInfo?.data?.eventType === 'leaderboard') {
          const leaderboardInfo = await backendApis.loadLeaderboardInfo(eventId)
          setLeaderboardInfo(leaderboardInfo?.data)

          if (
            leaderboardInfo?.data?.find(
              (e) =>
                e?.userId.toString() === UserStore?.userInfo?._id.toString(),
            )
          ) {
            setParticipated(true)
          }
        }
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonEventPage', 'enteredScreen', '')
    setIsLoading(false)
  }, [participated])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>
          {eventInfo?.eventTitle?.length > 20
            ? `${eventInfo?.eventTitle?.slice(0, 20)}..`
            : eventInfo?.eventTitle}
        </div>
        <div className='w-14' />
      </section>
    )
  }

  const MainImageSection = () => {
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.eventImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const SubImageSection = () => {
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.subImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const DramaPosterSection = ({
    dramaId = '66445534573d90c7474a0bb3',
    posterImage = 'https://assets.ilevit.com/404e45eb-8a6c-47e1-bd0f-87081285747d.png',
  }) => {
    return (
      <button
        type='button'
        aria-label='dramaPoster'
        className='w-full h-full pb-[10vw]'
        onClick={() => {
          backendApis.logToonAction('AltoonMainBanner', 'clickedDramaPoster', {
            dramaId,
          })
          const webUrl = 'https://alwayz-drama-front.ilevit.com'
          const redirectSearchParams = new URLSearchParams({
            token: AuthStore.token,
            randomString: Math.random().toString(36).slice(2, 10),
          })
          const prop = {
            screen: 'Drama',
            prop: {
              eventPageMapping: {
                redirectUrl: `${webUrl}/?${redirectSearchParams}`,
              },
              isFrom: 'Altoon',
              dramaId,
            },
          }
          window.location.href = `#navigate.${JSON.stringify(prop)}`
        }}
      >
        <img src={posterImage} alt='' className='w-full h-full object-cover' />
      </button>
    )
  }

  const CTAButtonOne = () => {
    return (
      <div className='w-full py-[4vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaOneInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaOneInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaOneInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaOneInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaOneInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
                pop()
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaOneInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaOneInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaOneInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const CTAButtonTwo = () => {
    return (
      <div className='w-full pt-[4vw] pb-[10vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaTwoInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaTwoInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaTwoInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaTwoInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaTwoInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaTwoInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaTwoInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaTwoInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const ToonsAndNovelsList = () => {
    return (
      <div className='pb-4'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          {eventInfo?.toonListTitle || '이벤트 대상 작품목록'}
        </div>
        {novelsInfo?.length > 0 &&
          novelsInfo?.map((novel, index) => (
            <div
              key={novel?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainNovelComponent
                novel={novel}
                index={index}
                type='eventPage'
              />
            </div>
          ))}
        {toonsInfo?.length > 0 &&
          toonsInfo?.map((toon, index) => (
            <div
              key={toon?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainToonComponent
                toon={toon}
                index={index}
                type='eventPage'
                component='eventPage_toon'
              />
            </div>
          ))}
      </div>
    )
  }

  const PollList = () => {
    const [selectedToonId, setSelectedToonId] = useState()
    const [isPollSubmitted, setIsPollSubmitted] = useState(
      moment(window.localStorage.getItem('pollSubmitted')).format(
        'YYYY-MM-DD',
      ) === moment().format('YYYY-MM-DD'),
    )

    useEffect(() => {
      console.log(isPollSubmitted, 'isPollSubmitted')
    }, [isPollSubmitted])

    return (
      <div className='pb-10'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          {eventInfo?.toonListTitle || '투표 대상 작품목록'}
        </div>
        <div className='grid grid-cols-2 gap-4 px-4'>
          {toonsInfo?.length > 0 &&
            toonsInfo?.map((toon, index) => (
              <button
                type='button'
                key={toon?._id}
                className={`relative flex flex-col items-center rounded-lg border bg-white ${
                  selectedToonId === toon?._id
                    ? 'border-red-500 border-[1vw]'
                    : ''
                }`}
                onClick={() => {
                  setSelectedToonId(toon?._id)
                }}
              >
                <img
                  src={
                    selectedToonId === toon?._id
                      ? '/images/toon/checkRed.png'
                      : '/images/toon/checkGray.png'
                  }
                  alt='poll'
                  className={`absolute top-[1vw] right-[1vw] z-10 ${
                    selectedToonId === toon?._id
                      ? 'w-[9vw] h-[9vw]'
                      : 'w-[10vw] h-[10vw]'
                  }`}
                />
                <MainToonComponent
                  toon={toon}
                  index={index}
                  type='pollEvent'
                  component='pollEventPage_toon'
                />
              </button>
            ))}
        </div>
        <div className='px-4 mt-6'>
          <button
            type='button'
            className={
              selectedToonId && !isPollSubmitted
                ? 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
                : 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
            }
            disabled={isPollSubmitted}
            onClick={async () => {
              if (!selectedToonId) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🎟️',
                  message: '투표할 작품을 선택해주세요!',
                  duration: 2000,
                })
              }
              if (selectedToonId) {
                const result = await backendApis.submitPoll(
                  eventInfo?._id,
                  selectedToonId,
                )
                if (result?.status === 200) {
                  ToastStore.toastOn({
                    type: 'success',
                    message: '투표가 완료되었어요!',
                    duration: 2000,
                  })
                  window.localStorage.setItem('pollSubmitted', new Date())
                  setIsPollSubmitted(true)
                  setSelectedToonId(null)
                } else {
                  ToastStore.toastOn({
                    type: 'error',
                    message:
                      '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                    duration: 2000,
                  })
                }
              }
            }}
          >
            {isPollSubmitted ? '오늘 투표 완료' : '투표하기'}
          </button>
        </div>
      </div>
    )
  }

  const LeaderboardSection = () => {
    return (
      <div className='pb-4'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          실시간 리더보드
        </div>
        <div className='flex flex-col justify-center items-center mx-[4vw] mb-[4vw] pt-6 pb-4 font-bold text-[3.8vw] bg-white text-center rounded-lg '>
          내 순위
          <div
            className={
              participated
                ? 'w-full text-[6vw] text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw] '
                : 'w-full text-[4.2vw] text-gray-500 text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw]'
            }
          >
            {participated
              ? `${
                  (leaderboardInfo?.find(
                    (e) =>
                      e?.userId.toString() ===
                      UserStore?.userInfo?._id.toString(),
                  )?.rank > 98
                    ? '+99'
                    : leaderboardInfo?.find(
                        (e) =>
                          e?.userId.toString() ===
                          UserStore?.userInfo?._id.toString(),
                      )?.rank) || '- '
                }
            위`
              : '이벤트 참여 전입니다'}
          </div>
        </div>
        <div className='bg-white rounded-lg mx-4 p-4'>
          <div className='flex flex-row justify-between px-4 py-3 text-[3.6vw] font-bold text-center text-gray-600 border-b border-gray-100'>
            <div className='w-[10vw]'>순위</div>
            <div>닉네임</div>
            <div className='w-[10vw]'>점수</div>
          </div>
          {leaderboardInfo?.map((leaderboard, index) => (
            <div
              key={leaderboard?._id}
              className='flex flex-row justify-between px-4 py-3 text-[3.4vw] border-b border-gray-100'
            >
              <div className='w-[10vw] text-center'>{index + 1}</div>
              <div>
                {leaderboard?.userName} (
                {leaderboard?.phoneNumber?.slice(7, 11) || '****'})
              </div>
              <div className='w-[10vw] text-center text-[#FF3E3E] font-bold'>
                {commaNumber(leaderboard?.count) || 0}
              </div>
            </div>
          ))}
        </div>
        <div className='px-4 mt-6'>
          <button
            type='button'
            className={
              participated
                ? 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
                : 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
            }
            disabled={participated}
            onClick={async () => {
              console.log('11')

              const result = await backendApis.participateLeaderboard(
                eventInfo?._id,
              )
              console.log(result, 'result')

              if (result?.status === 200) {
                setParticipated(true)
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            }}
          >
            {participated ? '참여 완료' : '이벤트 참여하기'}
          </button>
        </div>
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div className='w-full px-6 pt-4 pb-20 bg-gray-200'>
        <div className='text-[3.6vw] font-semibold'>유의사항</div>
        <THImageText
          fontSize={2.8}
          style={{
            color: '#8E8D9B',
          }}
          text={`${eventInfo?.notice}`}
        />
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        style={{
          backgroundColor: `#${eventInfo?.backgroundColor}`,
        }}
      >
        {eventInfo && (
          <>
            <HeaderComponent />

            {(!eventInfo?.eventType || eventInfo.eventType === 'basic') && (
              <>
                <MainImageSection />
                {eventInfo?.ctaOneInfo?.ctaText && <CTAButtonOne />}
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.ctaTwoInfo?.ctaText && <CTAButtonTwo />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'poll' && (
              <>
                <MainImageSection />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <PollList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'leaderboard' && (
              <>
                <MainImageSection />
                <LeaderboardSection />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo?.eventType === 'drama' && (
              <>
                <MainImageSection />
                <DramaPosterSection
                  dramaId={eventInfo?.dramaId}
                  posterImage={eventInfo?.dramaPosterImage}
                />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}
          </>
        )}
        {isLoading && <LoadingIndicator isLoading={isLoading} />}
      </div>
    </AppScreen>
  )
})

export default AltoonEventPage
