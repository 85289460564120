/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import AB from '../../utils/ab'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'
import AlwayzImage from '../atoms/AlwayzImage'
import AltoonTagComponent from './AltoonTagComponent'

const MainToonComponent = ({
  toon = {},
  index = {},
  type = 'main',
  isToday = false,
  component = 'undefined',
}) => {
  const { pop, push, replace } = useMyFlow()
  const isOnWaitForFreePromotion =
    moment().isAfter(toon?.promotionInfo?.waitForFree?.eventStartedAt) &&
    moment().isBefore(toon?.promotionInfo?.waitForFree?.eventEndedAt)

  const onClickHandler = async () => {
    if (toon?.isAdultOnly) {
      if (AltoonUserStore.userData?.isAdult === false) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `성인 인증이 필요한 작품입니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
            onClick: () => {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'SelfAuthenticationScreen',
                prop: {
                  onSuccess: async () => {
                    pop()
                  },
                  benefitType: 'toon',
                  defaultSuccessHandler: true,
                },
              })}`
            },
          },
        })
      }

      if (AltoonUserStore.userData?.isAdult === true) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '안내',
            description: `기존 인증 내역으로 성인 인증이 완료되었습니다.`,
            src: `/images/toon/isAdultIcon.png`,
            text: '확인',
            forceOpen: true,
          },
        })
        // ToastStore.toastOn({
        //   type: 'checkemoji',
        //   message: `기존 인증 내역으로 성인 인증이 완료되었습니다`,
        //   duration: 1500,
        //   emoji: '✔️',
        // })
        push('AltoonDetailPage', {
          toonId: toon?._id,
          component,
          index,
        })
      }

      if (!AltoonUserStore.userData?.isAdult) {
        const authInfo = await backendApis.loadAuthInfo()

        if (authInfo?.status === 200) {
          const userData = await backendApis.loadUserData()
          AltoonUserStore.set('userData.isAdult', userData?.data?.isAdult)

          if (userData?.data?.isAdult === false) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `성인 인증이 필요한 작품입니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
                onClick: () => {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'SelfAuthenticationScreen',
                    prop: {
                      onSuccess: async () => {
                        pop()
                      },
                      benefitType: 'toon',
                      defaultSuccessHandler: true,
                    },
                  })}`
                },
              },
            })
          }

          if (userData?.data?.isAdult === true) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: '안내',
                description: `기존 인증 내역으로 성인 인증이 완료되었습니다.`,
                src: `/images/toon/isAdultIcon.png`,
                text: '확인',
                forceOpen: true,
              },
            })
            push('AltoonDetailPage', {
              toonId: toon?._id,
              component,
              index,
            })
          }
        } else if (authInfo?.status === 201) {
          THModalStore.setType({
            type: 'reward',
            config: {
              rewardName: '안내',
              description: `성인 인증이 필요한 작품입니다.`,
              src: `/images/toon/isAdultIcon.png`,
              text: '확인',
              forceOpen: true,
              onClick: () => {
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'SelfAuthenticationScreen',
                  prop: {
                    onSuccess: async () => {
                      pop()
                    },
                    benefitType: 'toon',
                    defaultSuccessHandler: true,
                  },
                })}`
              },
            },
          })
        }
      }
      return
    }

    // 올팜 물미션
    if (type === 'onboarding') {
      const nextChapter = await backendApis.loadChapterByEpisode(toon?._id, 0)

      if (nextChapter?.status === 200) {
        replace('AltoonReaderPage', {
          state: {
            chapterInfo: nextChapter?.data,
            toonInfo: toon,
            episode: 1,
            rentPeriod: 3,
            component,
            index,
          },
          component: 'onboardingPage_chapterComponent',
        })
      } else if (nextChapter?.status === 204) {
        ToastStore.networkError()
        backendApis.logToonError(
          'mainToonComponent',
          'loadChapterByEpisode',
          nextChapter?.status,
        )
      }
    } else {
      push('AltoonDetailPage', {
        toonId: toon?._id,
        component,
        index,
      })
    }
  } // onClickHandler 끝

  // 최근 본 작품
  if (type === 'horizontal') {
    return (
      <button
        type='button'
        className='mr-1'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='relative'>
          <AlwayzImage
            src={resize(toon?.thumbnail, 236, 236, 'fill')}
            alt={toon?.title}
            lazy
            className='w-[21vw] h-[28vw] rounded-lg border border-[#F5F5F5] min-w-[21vw] min-h-[28vw]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[1vw] right-[1vw] w-[9vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[3.6vw] h-[3.6vw]'
            />
          )}
        </div>
        <div className='py-1'>
          <h3 className='font-bold text-start text-[2.8vw] mb-1'>
            {toon?.title?.length > 8
              ? `${toon?.title.slice(0, 8)}...`
              : `${toon?.title}`}
          </h3>
        </div>
      </button>
    )
  }

  // 오늘 인기 TOP 10
  if (type === 'trending') {
    return (
      <>
        <button
          type='button'
          className='px-[6vw] relative'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='relative'>
            <AlwayzImage
              lazy
              src={resize(toon?.thumbnail, 254, 340, 'fill')}
              alt={toon?.title}
              className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw] z-10'
            />

            {isOnWaitForFreePromotion && (
              <img
                src='/images/toon/waitForFreeIcon.png'
                alt='ticket'
                className='absolute bottom-[1vw] right-[1vw] w-[13vw] h-auto'
              />
            )}
            {toon?.isAdultOnly && (
              <img
                src='/images/toon/isAdultIcon.png'
                alt='ticket'
                className='absolute top-[1vw] right-[1vw] w-[4vw] h-[4vw]'
              />
            )}
          </div>
          <div
            className={`${
              index === 9 ? 'text-[18vw] -left-[6vw]' : 'text-[20vw] left-0'
            }  justify-start items-end flexRow font-bold text-gray-600 opacity-60 absolute bottom-0 w-[21vw] z-0`}
          >
            {index + 1}
          </div>
          <div className='py-1'>
            <h3 className='font-bold text-center text-[3.4vw] mb-1'>
              {toon?.title?.length > 8
                ? `${toon?.title.slice(0, 8)}...`
                : `${toon?.title}`}
            </h3>
          </div>
        </button>
      </>
    )
  }

  // 새로운 작품 등장!
  if (type === 'new') {
    return (
      <>
        <button
          type='button'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='relative'>
            <AlwayzImage
              lazy
              src={resize(toon?.thumbnail, 254, 340, 'fill')}
              alt={toon?.title}
              className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
            />
            <img
              src='/images/toon/newIcon.png'
              alt=''
              className='absolute top-[1vw] left-[1vw] w-[8vw] h-[3.5vw]'
            />
            {isOnWaitForFreePromotion && (
              <img
                src='/images/toon/waitForFreeIcon.png'
                alt='ticket'
                className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
              />
            )}
            {toon?.isAdultOnly && (
              <img
                src='/images/toon/isAdultIcon.png'
                alt='ticket'
                className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
              />
            )}
          </div>
          <div className='py-1 flex flex-col items-start h-[16vw]'>
            <h3 className='font-bold text-start text-[3.4vw]'>
              {toon?.title?.length > 24
                ? `${toon?.title.slice(0, 24)}..`
                : `${toon?.title}`}
            </h3>
            <div className='text-[2.6vw] mb-1'>{toon?.author}</div>
          </div>
        </button>
      </>
    )
  }

  // 독자 픽! TOP 3
  if (type === 'commentRecommend') {
    return (
      <button
        type='button'
        className='pr-[4vw] flexRow'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='relative'>
          <AlwayzImage
            lazy
            src={resize(toon?.thumbnail, 254, 340, 'fill')}
            alt={toon?.title}
            className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[4.6vw] h-[4.6vw]'
            />
          )}
        </div>
        <div className='w-[50vw] min-w-[50vw] h-full min-h-[40vw] flex flex-col justify-between px-[2vw] text-start'>
          <h3 className='py-[2vw] font-bold text-[3.8vw]'>
            {toon?.title?.length > 12
              ? `${toon?.title.slice(0, 12)}..`
              : `${toon?.title}`}
          </h3>
          <div className='flex flex-row pb-[2vw] text-[2.8vw]'>
            {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => (
              <AltoonTagComponent key={Math.random()} tag={tag} />
            ))}
          </div>
          <div className='w-full min-h-[20vw] p-[2vw] rounded text-[2.6vw] mb-1 bg-[#EBEBEB]'>
            <p className='flex flex-row items-center justify-start pb-[2vw]'>
              {[1, 2, 3, 4, 5].map((star) => (
                <img
                  key={star}
                  alt=''
                  style={{
                    marginRight: 2,
                    width: '2vw',
                    height: '2vw',
                  }}
                  src='/images/toon/star.png'
                />
              ))}
            </p>
            {toon?.bestComment?.length > 70
              ? `${toon?.bestComment.slice(0, 70)}..`
              : toon.bestComment
              ? `${toon?.bestComment}`
              : '너무 재밌어요! 이거 보다가 시간 가는줄 몰랐네요,, 생전 안 하던 결제까지 했어요,ㅎㅎㅎ,,'}
          </div>
        </div>
      </button>
    )
  }

  // 바텀탭 만화->완결 탭->올툰 Pick!
  if (type === 'altoonRecommend') {
    return (
      <button
        type='button'
        className='pr-[4vw] flexRow'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='relative'>
          <AlwayzImage
            lazy
            src={resize(toon?.thumbnail, 254, 340, 'fill')}
            alt={toon?.title}
            className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[4.6vw] h-[4.6vw]'
            />
          )}
        </div>
        <div className='w-[50vw] min-w-[50vw] h-full min-h-[40vw] flex flex-col justify-between px-[2vw] text-start'>
          <h3 className='py-[2vw] font-bold text-[3.8vw]'>
            {toon?.title?.length > 12
              ? `${toon?.title.slice(0, 12)}..`
              : `${toon?.title}`}
          </h3>
          <div className='flex flex-row pb-[2vw] text-[2.8vw]'>
            {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => (
              <AltoonTagComponent key={Math.random()} tag={tag} />
            ))}
          </div>
          <div className='w-full min-h-[20vw] p-[2vw] rounded text-[2.6vw] mb-1 bg-[#EBEBEB]'>
            {toon?.description?.length > 84
              ? `${toon?.description.slice(0, 84)}..`
              : `${toon?.description}`}
          </div>
        </div>
      </button>
    )
  }

  // 미리보기 한 컷!
  if (type === 'preview') {
    return (
      <>
        <button
          type='button'
          className='pr-[4vw]'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='relative'>
            <AlwayzImage
              lazy
              src={resize(toon?.previewInfo?.imageUri, 1000, 1250, 'fill')}
              alt={toon?.title}
              className='w-[80vw] h-[100vw] rounded-lg border border-[#F5F5F5] min-w-[80vw] min-h-[100vw]'
            />
            {isOnWaitForFreePromotion && (
              <img
                src='/images/toon/waitForFreeIcon.png'
                alt='ticket'
                className='absolute bottom-[2vw] right-[1vw] w-[20vw] h-auto'
              />
            )}
            {toon?.isAdultOnly && (
              <img
                src='/images/toon/isAdultIcon.png'
                alt='ticket'
                className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
              />
            )}
          </div>
          <div className='text-start py-1'>
            <h3 className='text-[3.8vw] font-bold mb-1'>
              {toon?.title?.length > 24
                ? `${toon?.title.slice(0, 24)}...`
                : `${toon?.title}`}
            </h3>
            <h3 className='text-[3vw] mb-1'>
              {toon?.previewInfo?.introduction?.length > 36
                ? `${toon?.previewInfo?.introduction?.slice(0, 36)}...`
                : `${toon?.previewInfo?.introduction}`}
            </h3>
          </div>
        </button>
      </>
    )
  }

  // 바텀탭 만화->완결 탭->장르별
  // todo: 어떤 장르 탭인지 알려줘야함
  if (type === 'genre') {
    return (
      <>
        <button
          type='button'
          className='flex justify-center mb-[2vw]'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='flexCol items-start'>
            <div className='relative'>
              <AlwayzImage
                lazy
                src={resize(toon?.thumbnail, 420, 560, 'fill')}
                alt={toon?.title}
                className='w-[45vw] h-[60vw] rounded-lg border border-[#F5F5F5] min-w-[45vw] min-h-[60vw]'
              />
              {isOnWaitForFreePromotion && (
                <img
                  src='/images/toon/waitForFreeIcon.png'
                  alt='ticket'
                  className='absolute bottom-[1vw] right-[1vw] w-[16vw] h-auto'
                />
              )}
              {toon?.isAdultOnly && (
                <img
                  src='/images/toon/isAdultIcon.png'
                  alt='ticket'
                  className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                />
              )}
            </div>
            <div className='text-start py-1'>
              <h3 className='text-[3.8vw] font-bold mb-1 text-clip'>
                {toon?.title?.length > 15
                  ? `${toon?.title.slice(0, 15)}..`
                  : `${toon?.title}`}
              </h3>
              <h3 className='text-[3vw] mb-1'>
                {toon?.author?.length > 20
                  ? `${toon?.author.slice(0, 20)}...`
                  : `${toon?.author}`}
              </h3>
            </div>
          </div>
        </button>
      </>
    )
  }

  // 작품으로 바로 가는 배너와 이벤트 페이지로 가는 배너가 있음
  if (type === 'eventPage') {
    return (
      <button
        type='button'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
        className='flex flex-row w-full h-full'
      >
        <div className='relative w-[44vw]'>
          <img
            src={toon?.thumbnail}
            alt={toon?.title}
            className='w-[40vw] h-[40vw] rounded-lg border border-[#F5F5F5]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[1vw] right-[1vw] w-[15vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
            />
          )}
        </div>
        <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[4vw]'>
          <div className='h-full flex flex-col justify-between'>
            <div>
              <h3 className='font-bold'>{toon?.title}</h3>
              <div className='pt-1 pb-2 flex flex-row text-[2.8vw] justify-start text-start'>
                <p>{toon?.author}</p>
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((toon?.ratingScore / toon?.ratingCount) * 100) /
                    100 || 0}
                </p>
              </div>
              <div className='text-[2.8vw] justify-start text-gray-600 text-start'>
                {toon?.isAdultOnly
                  ? '본인인증이 필요한 작품입니다.'
                  : toon?.description?.length > 84
                  ? `${toon?.description?.slice(0, 84)}..`
                  : toon?.description}
              </div>
            </div>
            {type !== 'onboarding' && (
              <div className='flex flex-row mt-2 text-[2.8vw]'>
                {toon?.tagList?.hot === true && (
                  <AltoonTagComponent key={Math.random()} tag='hot' />
                )}

                {toon?.tagList?.complete === true && (
                  <AltoonTagComponent key={Math.random()} tag='complete' />
                )}

                {toon?.tagList?.new && (
                  <AltoonTagComponent key={Math.random()} tag='new' />
                )}
                {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => {
                  return <AltoonTagComponent key={Math.random()} tag={tag} />
                })}
              </div>
            )}
          </div>
        </div>
      </button>
    )
  }

  // 작품 읽기 화면 - 이 작가의 다른 작품
  if (type === 'otherToonsByAuthor') {
    return (
      <button
        type='button'
        className='pr-[4vw] flexRow'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='relative'>
          <AlwayzImage
            lazy
            src={resize(toon?.thumbnail, 254, 340, 'fill')}
            alt={toon?.title}
            className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[4.6vw] h-[4.6vw]'
            />
          )}
        </div>
        <div className='w-[50vw] min-w-[50vw] h-full min-h-[40vw] flex flex-col justify-between px-[2vw] text-start'>
          <h3 className='py-[2vw] font-bold text-[3.8vw]'>
            {toon?.title?.length > 12
              ? `${toon?.title.slice(0, 12)}..`
              : `${toon?.title}`}
          </h3>
          <div className='flex flex-row pb-[2vw] text-[2.8vw]'>
            {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => (
              <AltoonTagComponent key={Math.random()} tag={tag} />
            ))}
          </div>
          <div className='w-full min-h-[20vw] p-[2vw] rounded text-[2.6vw] mb-1 bg-[#EBEBEB]'>
            <p className='flex flex-row items-center justify-start pb-[2vw]'>
              {[1, 2, 3, 4, 5].map((star) => (
                <img
                  key={star}
                  alt=''
                  style={{
                    marginRight: 2,
                    width: '2vw',
                    height: '2vw',
                  }}
                  src='/images/toon/star.png'
                />
              ))}
            </p>
            {toon?.bestComment?.length > 70
              ? `${toon?.bestComment.slice(0, 70)}..`
              : toon.bestComment
              ? `${toon?.bestComment}`
              : '너무 재밌어요! 이거 보다가 시간 가는줄 몰랐네요,, 생전 안 하던 결제까지 했어요,ㅎㅎㅎ,,'}
          </div>
        </div>
      </button>
    )
  }

  // 추천작품 보러가기 (웹툰 리더기 맨 밑에 기존에있던 '추천작품 보러가기')
  if (type === 'relatedToon') {
    return (
      <button
        type='button'
        key={toon?._id || index}
        onClick={async () => {
          onClickHandler()
        }}
        className='flex flex-row w-full h-full'
      >
        <div className='relative w-[30vw]'>
          <img
            src={toon?.thumbnail}
            alt={toon?.title}
            className='w-[30vw] h-[30vw] rounded-lg border border-[#F5F5F5]'
          />
          {isOnWaitForFreePromotion && (
            <img
              src='/images/toon/waitForFreeIcon.png'
              alt='ticket'
              className='absolute bottom-[1vw] right-[1vw] w-[12vw] h-auto'
            />
          )}
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[4vw] h-[4vw]'
            />
          )}
        </div>
        <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[3vw]'>
          <div className='h-full flex flex-col justify-between'>
            <div>
              <h3 className='font-bold'>{toon?.title}</h3>
              <div className='pt-1 pb-2 flex flex-row text-[2.4vw] justify-start text-start'>
                <p>{toon?.author}</p>
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((toon?.ratingScore / toon?.ratingCount) * 100) /
                    100 || 0}
                </p>
              </div>
              <div className='text-[2.4vw] justify-start text-gray-600 text-start'>
                {toon?.isAdultOnly
                  ? '본인인증이 필요한 작품입니다.'
                  : toon?.description?.length > 84
                  ? `${toon?.description?.slice(0, 84)}..`
                  : toon?.description}
              </div>
            </div>
          </div>
        </div>
      </button>
    )
  }

  // 비슷한 작품 추천 (리더기 페이지)
  if (type === 'relatedToonsReader') {
    return (
      <>
        <button
          type='button'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='relative'>
            <AlwayzImage
              lazy
              src={resize(toon?.thumbnail, 254, 340, 'fill')}
              alt={toon?.title}
              className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
            />

            {isOnWaitForFreePromotion && (
              <img
                src='/images/toon/waitForFreeIcon.png'
                alt='ticket'
                className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
              />
            )}
            {toon?.isAdultOnly && (
              <img
                src='/images/toon/isAdultIcon.png'
                alt='ticket'
                className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
              />
            )}
          </div>
          <div className='py-1 flex flex-col items-start'>
            <h3 className='font-bold text-start text-[3.2vw] line-clamp-1'>
              {toon?.title?.length > 13
                ? `${toon?.title.slice(0, 13)}..`
                : `${toon?.title}`}
            </h3>
            <div
              className='mt-2 text-[2vw] overflow-hidden text-start'
              style={{
                maxWidth: '30vw',
                maxHeight: '12vw',
                display: 'block',
                whiteSpace: 'normal',
              }}
            >
              {toon?.tagList?.mainGenre?.slice(0, 1)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
              {toon?.tagList?.subGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          </div>
        </button>
      </>
    )
  }

  // 비슷한 작품 추천 (디테일 페이지)
  if (type === 'relatedToonsDetail') {
    return (
      <>
        <button
          type='button'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className='relative'>
            <AlwayzImage
              lazy
              src={resize(toon?.thumbnail, 254, 340, 'fill')}
              alt={toon?.title}
              className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw]'
            />

            {isOnWaitForFreePromotion && (
              <img
                src='/images/toon/waitForFreeIcon.png'
                alt='ticket'
                className='absolute bottom-[2vw] right-[1vw] w-[13vw] h-auto'
              />
            )}
            {toon?.isAdultOnly && (
              <img
                src='/images/toon/isAdultIcon.png'
                alt='ticket'
                className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
              />
            )}
          </div>
          <div className='py-1 flex flex-col items-start'>
            <h3 className='font-bold text-start text-[3.2vw] line-clamp-1'>
              {toon?.title?.length > 13
                ? `${toon?.title.slice(0, 13)}..`
                : `${toon?.title}`}
            </h3>
            <div
              className='mt-2 text-[2vw] overflow-hidden text-start'
              style={{
                maxWidth: '30vw',
                maxHeight: '12vw',
                display: 'block',
                whiteSpace: 'normal',
              }}
            >
              {toon?.tagList?.mainGenre?.slice(0, 1)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
              {toon?.tagList?.subGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          </div>
        </button>
      </>
    )
  }

  if (type === 'pollEvent') {
    return (
      <div className='flex flex-col items-center'>
        <div className='flex justify-center mb-[2vw]' key={toon?._id || index}>
          <div className='flexCol justify-center items-start'>
            <div className='relative'>
              <AlwayzImage
                lazy
                src={resize(toon?.thumbnail, 420, 560, 'fill')}
                alt={toon?.title}
                className='w-[40vw] h-[46vw] rounded-lg border border-[#F5F5F5] min-w-[40vw] min-h-[46vw] mt-[2vw]'
              />
              {isOnWaitForFreePromotion && (
                <img
                  src='/images/toon/waitForFreeIcon.png'
                  alt='ticket'
                  className='absolute bottom-[1vw] right-[1vw] w-[16vw] h-auto'
                />
              )}
              {toon?.isAdultOnly && (
                <img
                  src='/images/toon/isAdultIcon.png'
                  alt='ticket'
                  className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                />
              )}
            </div>
            <div className='text-start px-[1vw] pt-[2vw]'>
              <h3 className='text-[3.8vw] font-bold mb-1 text-clip'>
                {toon?.title?.length > 14
                  ? `${toon?.title.slice(0, 14)}..`
                  : `${toon?.title}`}
              </h3>
              <h3 className='text-[3vw] mb-1'>
                {toon?.author?.length > 20
                  ? `${toon?.author.slice(0, 20)}...`
                  : `${toon?.author}`}
              </h3>
            </div>
          </div>
        </div>
        <button
          type='button'
          className='w-[100%] mx-[4vw] mt-[3vw] mb-[2vw] py-[2vw] rounded-lg text-[3vw] border bg-[#F6F6FA]'
          onClick={() => {
            onClickHandler()
          }}
        >
          작품 보러가기
        </button>
      </div>
    )
  }

  // todo: type 만들기

  return (
    <button
      type='button'
      key={toon?._id || index}
      onClick={async () => {
        onClickHandler()
      }}
      className='flex flex-row mb-4 w-full h-[40vw]'
    >
      <div className='relative w-[44vw]'>
        <AlwayzImage
          src={resize(toon?.thumbnail, 248, 340, 'fill')}
          alt={toon?.title}
          className='w-[29vw] h-[40vw] rounded-lg border border-[#F5F5F5]'
          lazy
        />
        {isToday && (
          <img
            src='/images/toon/upIcon.png'
            alt='ticket'
            className='absolute top-[1vw] left-[1vw] w-[5vw] h-[3vw]'
          />
        )}
        {isOnWaitForFreePromotion && (
          <img
            src='/images/toon/waitForFreeIcon.png'
            alt='ticket'
            className='absolute bottom-[1vw] right-[1vw] w-[13vw] h-auto'
          />
        )}
        {toon?.isAdultOnly && (
          <img
            src='/images/toon/isAdultIcon.png'
            alt='ticket'
            className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
          />
        )}
      </div>
      <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[4vw]'>
        <div className='h-full flex flex-col justify-between'>
          <div>
            <h3 className='font-bold'>{toon?.title}</h3>
            <div className='pt-1 pb-2 flex flex-row text-[2.8vw] justify-start text-start'>
              <p>{toon?.author}</p>
              {type !== 'onboarding' && (
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((toon?.ratingScore / toon?.ratingCount) * 100) /
                    100 || 0}
                </p>
              )}
            </div>
            <div className='text-[2.8vw] justify-start text-gray-600 text-start'>
              {toon?.isAdultOnly
                ? '본인인증이 필요한 작품입니다.'
                : toon?.description?.length > 84
                ? `${toon?.description?.slice(0, 84)}..`
                : toon?.description}
            </div>
          </div>
          {type !== 'onboarding' && (
            <div className='flex flex-row mt-2 text-[2.8vw]'>
              {toon?.tagList?.hot === true && (
                <AltoonTagComponent key={Math.random()} tag='hot' />
              )}

              {toon?.tagList?.complete === true && (
                <AltoonTagComponent key={Math.random()} tag='complete' />
              )}

              {toon?.tagList?.new && (
                <AltoonTagComponent key={Math.random()} tag='new' />
              )}
              {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          )}
          {type === 'onboarding' && (
            <div className='flex flex-row items-center justify-center py-1 text-[3vw] bg-red-500 text-white font-semibold rounded'>
              보러가기
              <FiChevronRight className='inline-block ml-1' />
            </div>
          )}
        </div>
      </div>
    </button>
  )
}
export default MainToonComponent
