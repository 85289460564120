import { AdProvider, useAdConfig } from '@wetripod/alwayz-frontend-shared'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import ReactModal from 'react-modal'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { useSearchParam } from 'react-use'

import './App.css'
import EggMoneyAction from './actions/EggMoneyAction'
import LoadingIndicator from './components/atoms/loadingIndicator'
import RandomPick from './components/deal/game/randomPick'
import RockPaperScissors from './components/deal/game/rockPaperScissors'
import lazyImport from './components/lazyImport'
import Modal from './components/templates/modal'
import THBottomSheets from './components/treasureHunt/THBottomSheets'
import THModal from './components/treasureHunt/THModal'
import THSelectItem from './components/treasureHunt/THSelectItem'
import THAlert from './components/treasureHunt/molecules/THAlert'
import Watermelon from './games/watermelon/Watermelon'
import WatermelonArcade from './games/watermelon/WatermelonArcade'
import WatermelonTeamGame from './games/watermelon/WatermelonTeamGame'
import AddressInfoPage from './pages/AddressInfoPage'
import AlfarmBridgePage from './pages/AlfarmBridgePage'
import AlfarmCoffeeBridgePage from './pages/AlfarmCoffeeBridgePage'
import AlfarmTrial from './pages/AlfarmTrial'
import AlfluencerCMS from './pages/Alfluencer/CMS/Main'
import AdminScreen from './pages/AltoonCMS/AdminScreen'
import AltoonCMSLoginScreen from './pages/AltoonCMS/AltoonCMSLoginScreen'
import FindPasswordScreen from './pages/AltoonCMS/FindPasswordScreen'
import AltoonNovelDetailPage from './pages/AltoonNovelDetailPage'
import AltoonNovelReaderPage from './pages/AltoonNovelReaderPage'
import AlwalkCashwalkPage from './pages/AlwalkCashwalkPage'
import DailyCheckInPage from './pages/DailyCheckIn/DailyCheckInPage'
import OfferwallPage from './pages/DailyCheckIn/OfferwallPage'
import DecoBokMessagePage from './pages/DecoBokMessagePage'
import DecoBokPage from './pages/DecoBokPage'
import EndPage from './pages/EndPage'
import ErrorPage from './pages/ErrorPage'
import ExpireHistoryPage from './pages/ExpireHistoryPage'
import GroupPurchase from './pages/GroupPurchase'
import GroupPurchaseRegister from './pages/GroupPurchaseRegister'
import GroupPurchaseWithdraw from './pages/GroupPurchaseWithdraw'
import MoneyTreePointExchangePage from './pages/MoneyTreePointExchangePage'
import RafflePage from './pages/RafflePage'
import TestPage from './pages/TestPage'
import TreasureBoxTutorialPage from './pages/TreasureBoxTutorialPage'
import ZeroWonStoreFriendItemPage from './pages/ZeroWonStoreFriendItemPage'
import ZeroWonStoreItemPage from './pages/ZeroWonStoreItemPage'
import ZeroWonStorePage from './pages/ZeroWonStorePage'
import BrandDealPage from './pages/deal/BrandDealPage'
import CategoryDealPage from './pages/deal/CategoryDealPage'
import LureDealPage from './pages/deal/LureDealPage'
import PriceSortDealPage from './pages/deal/PriceSortDealPage'
import SellerDealPage from './pages/deal/SellerDealPage'
import TimeDealPage from './pages/deal/TimeDealPage'
import TreasureDealPage from './pages/deal/TreasureDealPage'
import VisitedTimeDealPage from './pages/deal/VisitedTimeDealPage'
import { Stack } from './stackflow.ts'
import AuthStore from './stores/AuthStore'
import EggMoneyStore from './stores/EggMoney/EggMoneyStore'
import ItemImpressionStore from './stores/ItemImpressionStore'
import ToastStore from './stores/ToastStore'
import UserStore from './stores/UserStore'
import ABStore from './stores/treasureHunt/ABStore'
import RouteController from './utils/RouteController'
import { nativeMessageListener } from './utils/nativeMessageHandler'

moment.tz.setDefault('Asia/Seoul')

ReactModal.setAppElement('#root')

function RedirectToStack() {
  const location = useLocation()
  return <Navigate to={`/stack/altoon-main${location.search}`} replace />
}

const { EggMoneyHome } = lazyImport(
  () => import('./pages/EggMoney/EggMoneyHome'),
  'EggMoneyHome',
)

const { EggBox } = lazyImport(() => import('./pages/EggMoney/EggBox'), 'EggBox')

const { EggDetail } = lazyImport(
  () => import('./pages/EggMoney/EggDetail'),
  'EggDetail',
)
const { EggTradeInfo } = lazyImport(
  () => import('./pages/EggMoney/EggTradeInfo'),
  'EggTradeInfo',
)
const { EggTrade } = lazyImport(
  () => import('./pages/EggMoney/EggTrade'),
  'EggTrade',
)
const { EggTradeOrder } = lazyImport(
  () => import('./pages/EggMoney/EggTradeOrder'),
  'EggTradeOrder',
)

const { EggWithdraw } = lazyImport(
  () => import('./pages/EggMoney/EggWithdraw'),
  'EggWithdraw',
)

const { EggMoneyWithdrawDetail } = lazyImport(
  () => import('./pages/EggMoney/EggMoneyWithdrawDetail'),
  'EggMoneyWithdrawDetail',
)

const { TreasureHuntCarousel } = lazyImport(
  () => import('./pages/TreasureHuntCarousel'),
  'TreasureHuntCarousel',
)

const { EggStore } = lazyImport(
  () => import('./pages/EggMoney/EggStore'),
  'EggStore',
)

const { ApplePang } = lazyImport(
  () => import('./games/applePang/ApplePang'),
  'ApplePang',
)

const { TreasureHuntFriend } = lazyImport(
  () => import('./pages/TreasureHuntFriend'),
  'TreasureHuntFriend',
)

const { TreasureHuntPage } = lazyImport(
  () => import('./pages/TreasureHuntPage'),
  'TreasureHuntPage',
)

const { TreasureHuntLocationAuth } = lazyImport(
  () => import('./pages/TreasureHuntLocationAuth'),
  'TreasureHuntLocationAuth',
)

const { MVNOPreLaunchCarousel } = lazyImport(
  () => import('./pages/MVNOPreLaunchCarousel'),
  'MVNOPreLaunchCarousel',
)

const { MoneyTreePage } = lazyImport(
  () => import('./pages/MoneyTreePage'),
  'MoneyTreePage',
)

const { CheckInPigPage } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInPigPage'),
  'CheckInPigPage',
)

const { CheckInManbogiPage } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInManbogiPage'),
  'CheckInManbogiPage',
)

const { CheckInDrawHistoryScreen } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInDrawHistoryScreen'),
  'CheckInDrawHistoryScreen',
)

const { CheckInGifticonUpgradeScreen } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInGifticonUpgradeScreen'),
  'CheckInGifticonUpgradeScreen',
)

const { CheckInHistoryScreen } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInHistoryScreen'),
  'CheckInHistoryScreen',
)

const { CheckInExchangeStorePage } = lazyImport(
  () => import('./pages/DailyCheckIn/CheckInExchangeStorePage'),
  'CheckInExchangeStorePage',
)

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const coffeeFlag = useSearchParam('coffeeFlag')
  const channelType = useSearchParam('channelType')
  const token = useSearchParam('token')
  const impressionId = useSearchParam('impressionId')
  const previousIsFrom = useSearchParam('previousIsFrom')
  const givenJourneyBlockFromFront = useSearchParam('journeyBlock') || null // unused; for backward compatibility
  const givenJourneyBlockBase = useSearchParam('journeyBlockBase') || null
  const givenEnteringComponent =
    useSearchParam('givenEnteringComponent') || null

  // Should go in deal wrapper
  const handleVisibilityChange = async () => {
    if (document.hidden) {
      console.debug(`Webscreen going hidden`)
      // Flush impressions to server
      await ItemImpressionStore.uploadAndClearImpressions()
    } else {
      console.debug(`Webscreen now visible`)
      // Refresh visible impressions
      ItemImpressionStore.increaseScreenResetCount()
    }
  }

  // initialize deals
  useEffect(() => {
    ItemImpressionStore.setPreviousIsFrom(previousIsFrom)
    if (impressionId) ItemImpressionStore.setImpressionId(impressionId)

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // initialize token on searchParams
  useEffect(() => {
    if (AuthStore.token !== token && token) {
      AuthStore.setToken(token)
    }
  }, [token])

  // initialize with backend
  useEffect(() => {
    if (AuthStore.token) {
      const journeyBlockBase =
        givenJourneyBlockBase || givenJourneyBlockFromFront
      if (journeyBlockBase) {
        ItemImpressionStore.setJourneyBlockBase(journeyBlockBase)
      }
      if (givenEnteringComponent) {
        UserStore.setEnteringComponent(givenEnteringComponent)
      }

      Promise.all([UserStore.loadAbInfo()]).then(() => {
        // item shouldn't render before above steps are all done
        if (
          !window.location.href.includes('/eggMoney') ||
          process.env.NODE_ENV === 'development'
        ) {
          setIsLoading(false)
        }
      })
    }
  }, [AuthStore.token, givenJourneyBlockBase, givenJourneyBlockFromFront])

  useEffect(() => {
    if (window.location.href.includes('altoon-cms')) {
      setIsLoading(false)
    }
    if (window.location.href.includes('alfluencer/cms')) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (coffeeFlag === 'true') {
      window.location.href = `/alfarmCoffeeBridgePage?channelType=${channelType}`
    }

    window.addEventListener('message', nativeMessageListener)
    document.addEventListener('message', nativeMessageListener)

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'ready',
        data: {
          webscreenOptions: {
            hardwareBackPressEnabled: true,
          },
        },
      }),
    )

    return () => {
      window.removeEventListener('message', nativeMessageListener)
      document.removeEventListener('message', nativeMessageListener)
    }
  }, [])

  useEffect(() => {
    if (window.location.href.includes('/eggMoney')) {
      setIsLoading(!EggMoneyStore?.initiated)
    }
  }, [EggMoneyStore?.initiated])

  return (
    <BrowserRouter>
      {!isLoading && <RoutesWrapper />}

      <RoutesWebEventWrapper />

      <THBottomSheets />
      <Modal />
      <THModal />
      <Toaster
        position='top-center'
        toastOptions={{
          style: {
            marginTop: '35vw',
            fontFamily: 'maplestory',
            color: '#965F42',
            fontWeight: 'bold',
          },
        }}
      />
      <THAlert />

      <RouteController />
      {ToastStore.backgroundOn && (
        <div className='pointer-events-none z-[100] fixed w-[100vw] h-[100vh] bg-black/30' />
      )}
    </BrowserRouter>
  )
}

function RoutesWrapper() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (location.pathname.includes('/eggMoney')) {
      let path = location.pathname
      if (location.pathname === '/eggMoney/') {
        path = '/eggMoney'
      }

      EggMoneyAction.userLog({ type: 'page', tag: path })
    }
  }, [location.pathname])

  const { naver: naverConfig, targetpick: targetpickConfig } = useAdConfig({
    service: 'dailycheckin',
    platform: UserStore.offerwallInfo.platform === 'A' ? 'android' : 'ios',
  })

  return (
    <AdProvider configs={{ naver: naverConfig, targetpick: targetpickConfig }}>
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>
          <Route exact path='/error' element={<ErrorPage />} />
          <Route exact path='/eggMoney' element={<EggMoneyHome />} />
          <Route exact path='/eggMoney/eggBox' element={<EggBox />} />
          <Route exact path='/eggMoney/eggStore' element={<EggStore />} />
          <Route exact path='/eggMoney/eggTrade' element={<EggTrade />} />
          <Route
            exact
            path='/eggMoney/eggTradeOrder'
            element={<EggTradeOrder />}
          />
          <Route exact path='/eggMoney/eggWithdraw' element={<EggWithdraw />} />
          <Route
            exact
            path='/eggMoney/eggWithdrawDetail'
            element={<EggMoneyWithdrawDetail />}
          />
          <Route exact path='/eggMoney/tradeInfo' element={<EggTradeInfo />} />
          <Route exact path='/eggMoney/eggDetail' element={<EggDetail />} />
          <Route exact path='/treasurebox' element={<EndPage />} />
          <Route
            exact
            path='/treasureboxtutorial'
            element={<TreasureBoxTutorialPage />}
          />
          <Route
            exact
            path='/deal/treasure-deal'
            element={<TreasureDealPage />}
          />
          <Route
            exact
            path='/deal/category-deal'
            element={<CategoryDealPage />}
          />
          <Route
            exact
            path='/deal/price-sort-deal'
            element={<PriceSortDealPage />}
          />
          <Route exact path='/deal/lure-deal' element={<LureDealPage />} />
          <Route exact path='/deal/brand-deal' element={<BrandDealPage />} />
          <Route exact path='/deal/time-deal' element={<TimeDealPage />} />
          <Route
            exact
            path='/deal/visited-time-deal'
            element={<VisitedTimeDealPage />}
          />
          <Route
            exact
            path='/deal/rock-paper-scissors'
            element={<RockPaperScissors />}
          />
          <Route exact path='/deal/seller-deal' element={<SellerDealPage />} />
          <Route exact path='/deal/random-pick' element={<RandomPick />} />
          <Route exact path='/daily-check-in' element={<DailyCheckInPage />} />
          <Route exact path='/check-in-pig' element={<CheckInPigPage />} />
          <Route
            exact
            path='/check-in-manbogi'
            element={<CheckInManbogiPage />}
          />
          <Route exact path='/money-tree' element={<MoneyTreePage />} />
          <Route exact path='/raffle' element={<RafflePage />} />
          <Route exact path='/offerwall' element={<OfferwallPage />} />
          <Route exact path='/expire-history' element={<ExpireHistoryPage />} />
          <Route
            exact
            path='/exchange-store'
            element={<CheckInExchangeStorePage />}
          />
          <Route
            exact
            path='/check-in-exchange-history'
            element={<CheckInHistoryScreen />}
          />
          <Route
            exact
            path='/check-in-draw-history'
            element={<CheckInDrawHistoryScreen />}
          />
          <Route
            exact
            path='/check-in-gifticon-upgrade'
            element={<CheckInGifticonUpgradeScreen />}
          />
          <Route
            exact
            path='/money-tree-point-exchange'
            element={<MoneyTreePointExchangePage />}
          />
          <Route
            exact
            path='/alwalk-cashwalk-event'
            element={<AlwalkCashwalkPage />}
          />

          <Route path='/altoon-main' element={<RedirectToStack />} />
          <Route path='/stack/*' element={<Stack />} />

          <Route
            exact
            path='/altoon-novel-detail'
            element={<AltoonNovelDetailPage />}
          />

          <Route
            exact
            path='/altoon-novel-reader'
            element={<AltoonNovelReaderPage />}
          />

          <Route
            exact
            path='/altoon-cms-login'
            element={<AltoonCMSLoginScreen />}
          />

          <Route exact path='/altoon-cms/main' element={<AdminScreen />} />

          <Route
            exact
            path='/altoon-cms-login/find-password'
            element={<FindPasswordScreen />}
          />

          <Route
            exact
            path='/alfarmBridgePage'
            element={<AlfarmBridgePage />}
          />
          <Route exact path='/alfarmTrial' element={<AlfarmTrial />} />
          <Route exact path='/groupPurchase' element={<GroupPurchase />} />
          <Route
            exact
            path='/groupPurchaseRegister'
            element={<GroupPurchaseRegister />}
          />
          <Route
            exact
            path='/groupPurchaseWithdraw'
            element={<GroupPurchaseWithdraw />}
          />
          <Route
            exact
            path='/alfarmCoffeeBridgePage'
            element={<AlfarmCoffeeBridgePage />}
          />
          <Route
            exact
            path='/alfarmBridgePage'
            element={<AlfarmBridgePage />}
          />
          <Route
            exact
            path='/zeroWonStore/zeroWonStorePage'
            element={<ZeroWonStorePage />}
          />
          <Route
            exact
            path='/zeroWonStore/addressInfo'
            element={<AddressInfoPage />}
          />
          <Route
            exact
            path='/zeroWonStore/zeroWonStoreItemPage'
            element={<ZeroWonStoreItemPage />}
          />
          <Route
            exact
            path='/zeroWonStore/zeroWonStoreFriendItemPage'
            element={<ZeroWonStoreFriendItemPage />}
          />
          <Route exact path='/zeroWonStore' element={<ZeroWonStorePage />} />
          <Route exact path='/' element={<TestPage />} />
          <Route exact path='/test' element={<TestPage />} />
          <Route exact path='/watermelon' element={<Watermelon />} />
          <Route
            exact
            path='/watermelon-arcade'
            element={<WatermelonArcade />}
          />
          <Route
            exact
            path='/watermelon/teamgame'
            element={<WatermelonTeamGame />}
          />
          <Route exact path='/treasureHunt' element={<TreasureHuntPage />} />
          <Route
            exact
            path='/treasureHunt/friend'
            element={<TreasureHuntFriend />}
          />
          <Route
            exact
            path='/treasureHuntCarousel'
            element={<TreasureHuntCarousel />}
          />
          <Route
            exact
            path='/treasureHunt/selectItem'
            element={<THSelectItem />}
          />
          <Route
            exact
            path='/mvnoprelaunch'
            element={<MVNOPreLaunchCarousel />}
          />
          <Route
            exact
            path='/treasureHunt/authorizationRequest'
            element={<TreasureHuntLocationAuth />}
          />
          <Route exact path='/applePang' element={<ApplePang />} />
          <Route exact path='/alfluencer/cms' element={<AlfluencerCMS />} />
        </Routes>
      </Suspense>
    </AdProvider>
  )
}

function RoutesWebEventWrapper() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route exact path='/bok-event' element={<DecoBokPage />} />
      <Route exact path='/bok-message' element={<DecoBokMessagePage />} />
    </Routes>
  )
}

export default observer(App)
